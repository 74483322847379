<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent
          class="py-8"
          title="BAM Friction Test Standardization"
          size="large"
        />

        <p class="font-bold">
          The
          <router-link class="link" to="/un-friction-bam"
            >BAM Friction Test</router-link
          >
          is a UN test used for sensitivity determination based on relative
          comparison to standard substances. A standardized procedure for the
          Verification of Standardization (VoS) or Round Robin testing with the
          ET Users Group is below. Results of testing to date are also given
          (for participants).
        </p>

        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              VERIFICATION OF STANDARDIZATION
            </h2>

            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Testing can be completed by any lab to verify standardization
              (procedures, calibration, substance, etc.). The steps below
              further detail completion of the verification or Round Robin
              testing.
            </p>

            <ol class="p-light list-decimal pl-10 mt-4">
              <li>Download and implement the detailed procedure,</li>
              <li>
                Obtain the Hodgdon Clays sample,
              </li>
              <li>
                Complete testing according to the detailed instructions in the
                respective standard Round Robin/VoS procedure (link at right
                under 'Standardized Procedures', if not shown, request access
                <router-link
                  style="color:#ef9a3d;"
                  to="/standardization-and-verification/round-robin-participation"
                  >here</router-link
                >), and
              </li>
              <li>
                Share your results with the ET Users Group community. You can
                <router-link class="link" to="/contact-us/"
                  >contact us here</router-link
                >.
              </li>
            </ol>

            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ET Users Group Result Using High-Speed Video (To Date)
            </h2>

            <p class="p-light">
              The plot below depict the Round Robin/VoS result for BAM Friction
              (per the above standardized procedure) with the average
              distribution indicated by a dotted line. A statistical cutoff for
              the data summary was established using a 95% confidence level,
              which corresponds to a 5% false positive rate.
            </p>

            <a
              :href="require('../../assets/BAMFriction-HSV-Generic.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/BAMFriction-HSV-Generic.png" />
            </a>
            <p
              class="p-light mt-6"
              v-if="$store.getters['user/userClasses']['role-friction-test']"
            >
              Further details of the results are documented in the summary
              presentation
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/BAM%20Friction%20Round%20Robin%20Summary.pdf?alt=media&token=1216d49f-d652-46df-aa7b-b1117d92efe8"
                >here</a
              >.
            </p>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <div
                v-if="$store.getters['user/userClasses']['role-friction-test']"
              >
                <h2
                  class="mb-6"
                  style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
                >
                  STANDARDIZED PROCEDURE
                </h2>
                <div class="mb-6 p-light">
                  <ul class="pl-10 list-disc pl-10">
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/BAM%20Friction%20RR%20Procedure.pdf?alt=media&token=6cfb1bfe-cd98-4da4-8bb3-d2e25b93ac24"
                        target="_blank"
                        rel="noreferrer noopener"
                        >BAM Friction VoS Procedure: High-speed Video (HSV)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                SUPPORT DOCUMENTS AND VIDEOS
              </h2>
              <ul class="p-light list-disc pl-10">
                <li>
                  Videos of reaction detection with high-speed video are
                  <router-link class="link" to="/un-friction-bam"
                    >here</router-link
                  >
                </li>
              </ul>
            </div>

            <h2
              class="my-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Statistics
            </h2>
            <p class="p-light">
              A significant part of any VoS or Round Robin effort is determining
              the criteria that defines agreement or disagreement between test
              facilities. It’s highly unlikely that the exact same parameters
              defining the reaction transition for a given material and test
              machine will be obtained by all the sites even if they were done
              exactly the same way. As such, it is likely that the results from
              each laboratory or within the same laboratory will differ. The
              criteria to say that the results are different or not
              <u>should be statistically based</u>. The ET Users Group has
              adopted the SRC Method or the
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/StatisticalRelativeComparisonMethod.pdf?alt=media&token=fd5b8bc1-c5de-4c4c-b12b-77bbf892e6ac"
                >Statistical Relative Comparison (SRC) Method</a
              >
              to determine if differences in the sensitivity test outcomes are
              indeed significantly different from the ET Users Group community
              given above.
            </p>
          </div>
          <div class="w-full">
            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Feedback
            </h2>
            <p class="p-light">
              If you have any feedback on the information found here or have
              completed testing please share your feedback and/or results with
              us
              <router-link class="link" to="/contact-us"
                >(Contact Us)</router-link
              >.
            </p>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-12 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
